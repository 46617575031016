import { Component } from 'react';
import './App.css';
import {Authenticator, ForgotPassword, Greetings, SignIn} from "aws-amplify-react"
import {CustomSignIn} from "./signin/Signin"
import '@aws-amplify/ui/dist/style.css';
import config from "./aws-exports"
import App from './App';
import { CustomForgotPassword } from './forgot_password/ForgotPassword';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

class AppWithAuth extends Component {
    
    state = {
        authState: ''
    }
    
    handleAuthStateChange = (state: string, back: boolean) => {
        if (state === 'signIn') {
            if(back === undefined && this.state.authState === 'forgotPassword'){ // If not a back request from forgot password
                toast("Votre mot de passe a été réinitialisé", {
                    type: 'info'
                })
            }
            this.setState({authState: state})
        }
        else if(state === 'forgotPassword'){
            this.setState({authState: state})
        }
        
    }
    
    render(){
        return (
            <Authenticator hide={[SignIn, Greetings, ForgotPassword]} onStateChange={this.handleAuthStateChange} amplifyConfig={config}>
                <CustomSignIn />
                <CustomForgotPassword />
                <App authState={this.state.authState}/>
            </Authenticator>  
        );
    }
}
    
export default AppWithAuth
