/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:a4e49cf5-702d-40b6-9f15-6a278a7cb72a",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_0ml8JROvg",
    "aws_user_pools_web_client_id": "3i6aji3pe4vq60h6k7emaa35ov",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://vjbo7fzl5jb7rldkwz3ldsdvfi.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
