import { Cask } from "../models"


export const getDataset = (numLot: number, casks:Array<Cask>) => {

    const dataset = [
        {
            columns: [
                {title: "Réf.", "width":{"wpx":100}},
                {title: "Numéro de lot", "width":{"wpx":100}},
                
                {title: "Date collecte", "width":{"wpx":150}},
                {title: "Commentaire collecte", "width":{"wpx":250}},
                {title: "Conformité collecte", "width":{"wpx":250}},
                
                {title: "Date réception", "width":{"wpx":150}},
                {title: "Commentaire réception", "width":{"wpx":250}},
                {title: "Conformité réception", "width":{"wpx":250}},

                {title: "Date reconditionnement", "width":{"wpx":150}},
                {title: "Commentaire reconditionnement", "width":{"wpx":250}},
                {title: "Conformité reconditionnement", "width":{"wpx":250}},
            ],
            data: 
                casks.filter(it => it.numLot === numLot).map(cask => {
                    return [
                        {value: cask.barcode},
                        {value: cask.numLot},
                        {value: cask.dateCollect ? new Date(cask.dateCollect).toLocaleString() : ""},
                        {value: cask.commentCollect ? cask.commentCollect : ""},
                        {value: cask.customField1 === true ? "Oui" : cask.customField1 === false ? "Non" : ""},

                        {value: cask.dateRecept ? new Date(cask.dateRecept).toLocaleString() : ""},
                        {value: cask.commentRecept ? cask.commentRecept : ""},
                        {value: cask.customField2 === true ? "Oui" : cask.customField2 === false ? "Non" : ""},

                        {value: cask.dateRecon ? new Date(cask.dateRecon).toLocaleString() : ""},
                        {value: cask.commentRecon ? cask.commentRecon : ""},
                        {value: cask.customField3 === true ? "Oui" : cask.customField3 === false ? "Non" : ""},

                    ]
                })
            
            
        }
    ]
    return dataset
} 