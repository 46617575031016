import { Component } from 'react';
import './App.css';

import Amplify from "aws-amplify"

import '@aws-amplify/ui/dist/style.css';
import config from "./aws-exports"
import Header from './navigation/header/Header';
import Menu from './navigation/menu/Menu';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import Futs from './futs/Futs';
import Actions from './actions/Actions';


Amplify.configure(config)

interface IPropsApp {
  authState: string
}

interface IStateApp {
  
}
class App extends Component<IPropsApp, IStateApp> {

  state = {
    futs: []
  }

  render(){
    if (this.props.authState === 'signedIn') {
      return (
        <div className="App">
          <BrowserRouter>
            <Header/>
            <Menu/>
            <Route exact path="/futs" render={() => <Futs />} />
            <Route exact path="/actions" render={() => <Actions/>} />
            <Redirect exact from={"/"} to={"/futs"} />
          </BrowserRouter>
        </div>
      )
    } else {
      return null
    }
  }
}

export default App
