import React, {Component} from "react"
import classes from "./AddFut.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Loader from 'react-loader-spinner'
import { AmplifyManager } from "../../helpers/AmplifyManager";
import { toast } from "react-toastify";

interface IProps {
    onDismissModal: () => void,
    onCreationSuccess: () => void
}

interface IState {
    creationInProgress: boolean,
    lot: undefined|number,
    futNumber: string
}

class AddFut extends Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            creationInProgress: false,
            futNumber: "",
            lot: undefined
        }
    }

    onCreateRequest = async() => {
        await this.setState({creationInProgress: true})

        if(this.state.lot === undefined){
            alert("Vous devez sélectionner un numéro de lot")
            return;
        }

        try {
            const cask = await AmplifyManager.getInstance().getCask(this.state.futNumber)
            
            // If cask doesn't exist yet, create it
            let creationSuccess = false
            if(cask === undefined){
                await AmplifyManager.getInstance().createCask(this.state.lot as number, this.state.futNumber)
                await AmplifyManager.getInstance().createCaskAction(this.state.futNumber, this.state.lot)
                creationSuccess = true
            }else if(cask !== undefined && cask.numLot === 0) {
                await AmplifyManager.getInstance().updateNumLot(cask, this.state.lot)
                await AmplifyManager.getInstance().createCaskAction(this.state.futNumber, this.state.lot)
                creationSuccess = true
            }else {
                toast("Ce fût existe déjà dans le référentiel", {
                    type: "error"
                })
                
            }
            
            await this.setState({creationInProgress: false})
            
            if(creationSuccess === true){
                this.props.onCreationSuccess()
            }
        }catch(error){
            console.error(error)
        }
    }

    onFutNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(isNaN(Number(event.target.value))){
            return
        }
        this.setState({
            futNumber: event.target.value
        })
    }

    onLotChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            lot: Number.parseInt(event.target.value)
        })
    }

    render() {
        return <>
            <div className={classes.PopupOverlay} onClick={this.props.onDismissModal}/>

            <div className={classes.AddFutPopupContainer}>
                <div className={classes.AddFutPopupContent}>
                    <label className={classes.AddFutPopupTitle}>Ajout d'un fût</label>

                    <label>Veuillez saisir les informations à propos du fût à ajouter</label>
                    
                    <br />
                    
                    <input className={classes.AddFutNumberInput} value={this.state.futNumber} type="text" pattern="\d*" maxLength={7} minLength={7} placeholder="Numéro de fût" onChange={this.onFutNumberChanged}/>

                    <br />

                    <select onChange={this.onLotChanged} disabled={this.state.creationInProgress === true} className={classes.AddFutStoreNodeSelect}>
                        <option disabled selected>Sélectionnez le lot :</option>
                        <option key={2} value={2}>Lot 2</option>
                        <option key={3} value={3}>Lot 3</option>
                        <option key={4} value={4}>Lot 4</option>
                        
                    </select>
                    
                    {this.state.creationInProgress
                        ? 
                        <div style={{marginTop: "15px", paddingTop: "12px", paddingBottom: "12px"}}>
                            <Loader type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                        </div>
                        : <button disabled={this.state.creationInProgress || this.state.futNumber.length !== 7 || this.state.lot === undefined}  onClick={this.onCreateRequest} className={classes.AddFutValidateButton}><FontAwesomeIcon icon={faPlus} size={"sm"}/> Créer le fût</button>}
                </div>
            </div>
        </>
    }
}


export default AddFut;
